import { EnvironmentTarget } from './environment-target';

export const environment = {
  target: EnvironmentTarget.Stage,
  production: false,
  apiUrl: 'https://staging-api.eros.com',
  interiorUrl: 'https://staging.erosads.com',
  exteriorUrl: 'https://staging.eros.com',
  contentServerUrl: 'https://staging-i.eros.com',
  socksUrl: 'https://staging-api.eros.com/cellar',
  toyboxUrl: 'https://toybox.prod.eros.systems',
  shuftiproUrl: 'https://backoffice.shuftipro.com/verifications',
  sumSubUrl:
    'https://test-api.sumsub.com/checkus#/applicant/{{personId}}/basicInfo?clientId=eros'
};
